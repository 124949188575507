import React, { useState, useEffect } from 'react';
import { fetchHolidayRequests, approveHolidayRequest, denyHolidayRequest } from './api';
import { toast } from 'react-toastify';
const { DateTime } = require('luxon');

const adjustToTimeZone = (dateString, timeZone = 'Europe/London') => {
	return DateTime.fromISO(dateString, { zone: 'utc' }).setZone(timeZone).toFormat('yyyy-MM-dd');
};
function AdminApproval() {
	const [requests, setRequests] = useState([]);
	const [denyReasons, setDenyReasons] = useState({});

	useEffect(() => {
		const loadHolidayRequests = async () => {
			try {
				const response = await fetchHolidayRequests();

				// Ajuster les dates au fuseau horaire dès la récupération
				const adjustedRequests = response.data.map((request) => ({
					...request,
					start_date: adjustToTimeZone(request.start_date, 'Europe/London'),
					end_date: adjustToTimeZone(request.end_date, 'Europe/London'),
					created_at: adjustToTimeZone(request.created_at, 'Europe/London'),
				}));

				setRequests(adjustedRequests);
			} catch (error) {
				toast.error('Failed to fetch holiday requests');
			}
		};

		loadHolidayRequests();
	}, []);

	const handleApprove = async (id) => {
		try {
			await approveHolidayRequest(id);
			toast.success('Holiday request approved successfully');
			setRequests(requests.filter(request => request.id !== id));
		} catch (error) {
			toast.error('Failed to approve holiday request');
		}
	};

	const handleDeny = async (id) => {
		if (!denyReasons[id]) {
			toast.error('Please provide a reason for denial');
			return;
		}

		try {
			await denyHolidayRequest(id, denyReasons[id]);
			toast.success('Holiday request denied successfully');
			setRequests(requests.filter(request => request.id !== id));
			setDenyReasons(prevReasons => ({ ...prevReasons, [id]: '' }));
		} catch (error) {
			toast.error('Failed to deny holiday request');
		}
	};

	const handleDenyReasonChange = (id, value) => {
		setDenyReasons(prevReasons => ({
			...prevReasons,
			[id]: value
		}));
	};

	const formatDate = (dateString, dayType) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		const formattedDate = `${day}/${month}/${year}`;
		// Append morning or afternoon based on dayType
		if (dayType === 'morning') {
			return `${formattedDate} (Morning)`;
		} else if (dayType === 'afternoon') {
			return `${formattedDate} (Afternoon)`;
		}
		return formattedDate; // For full day
	};

	const formatDateTime = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const calculateRequestedHours = (start, end, startDayType, endDayType) => {
		const startDate = new Date(start);
		const endDate = new Date(end);
		const publicHolidays = [
			"2025-01-01", // New Year's Day
			"2025-04-18", // Good Friday
			"2025-04-21", // Easter Monday
			"2025-05-05", // Early May bank holiday
			"2025-05-26", // Spring bank holiday
			"2025-08-25", // Summer bank holiday
			"2025-12-25", // Christmas Day
			"2025-12-26", // Boxing Day
		];

		const isPublicHoliday = (date) => {
			const formattedDate = date.toISOString().split("T")[0]; // Convertir en "YYYY-MM-DD"
			return publicHolidays.includes(formattedDate);
		};
		if (startDate.getTime() === endDate.getTime()) {
			if (isPublicHoliday(startDate)) {
				return 0; // Pas d'heures pour les jours fériés
			}
			return startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
		}

		let totalHours = 0;
		for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
			const dayOfWeek = date.getDay();
			if (dayOfWeek >= 1 && dayOfWeek <= 4) {
				if (isPublicHoliday(date)) {
					continue; // Ignorer les jours fériés
				}
				if (date.toDateString() === startDate.toDateString()) {
					totalHours += startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
				} else if (date.toDateString() === endDate.toDateString()) {
					totalHours += endDayType === 'morning' || endDayType === 'afternoon' ? 5 : 10;
				} else {
					totalHours += 10;
				}
			}
		}
		return totalHours;
	};

	return (
		<div className="container mx-auto p-6">
		<h1 className="text-3xl font-semibold mb-6 text-center">Approve or Deny Holiday Requests</h1>
		<div className="overflow-x-auto">
		<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
		<thead className="bg-gold">
		<tr>
		<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
		<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Email</th>
		<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
		<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">End Date (included)</th>
	<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Requested Vacation Hours</th>
	<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Vacation Hours Left</th>
	<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Created At</th>
	<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
	<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
	</tr>
	</thead>
	<tbody className="divide-y divide-gray-200">
		{requests.map((request) => (
			<tr key={request.id} className="hover:bg-gray-100">
			<td className="px-6 py-4 whitespace-nowrap">
				<div className="text-sm text-gray-900">{`${request.firstName} ${request.lastName}`}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{formatDate(request.start_date, request.start_day_type)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.end_date, request.end_day_type)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {calculateRequestedHours(request.start_date, request.end_date, request.start_day_type, request.end_day_type)} hours
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.vacation_hours}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDateTime(request.created_at)}</div>
                </td>
                <td>
                  <textarea
                    placeholder="Reason for denial"
                    value={denyReasons[request.id] || ''}
                    onChange={(e) => handleDenyReasonChange(request.id, e.target.value)}
                    className="w-full px-3 py-2 border rounded mt-2"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap flex flex-col space-y-2">
                  <button
                    onClick={() => handleApprove(request.id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleDeny(request.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
                  >
                    Deny
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminApproval;

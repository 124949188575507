import React, { useState, useEffect } from 'react';
import { fetchPlanning, fetchDepartments } from './api';
import { toast } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import 'react-toastify/dist/ReactToastify.css';
import { format, isWithinInterval, startOfMonth, endOfMonth } from 'date-fns';
import { uk } from 'date-fns/locale';
import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';

function Planning() {
	const [holidays, setHolidays] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [selectedDepartment, setSelectedDepartment] = useState('');
	const [dateRange, setDateRange] = useState({ start: '', end: '' });
	const [showListView, setShowListView] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortedHolidays, setSortedHolidays] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [viewMode, setViewMode] = useState('month'); // 'month' or 'year'
	const [rawHolidays, setRawHolidays] = useState([]);


	useEffect(() => {
		const loadDepartments = async () => {
			try {
				const response = await fetchDepartments();
				setDepartments(response.data);
			} catch (error) {
				toast.error('Failed to fetch departments');
			}
		};
		loadDepartments();
	}, []);

	const fetchHolidays = async (startStr, endStr, department) => {
		try {
			const response = await fetchPlanning(startStr, endStr, department);
			setRawHolidays(response.data); // Stocker les données brutes
			setHolidays(response.data); // Initialiser les données affichées
		} catch (error) {
			toast.error('Failed to fetch holiday planning');
		}
	};


	useEffect(() => {
		if (dateRange.start && dateRange.end) {
			fetchHolidays(dateRange.start, dateRange.end, selectedDepartment);
		}
	}, [dateRange, selectedDepartment]);

	const handleDatesChange = ({ startStr, endStr }) => {
		setDateRange({ start: startStr, end: endStr });
	};

	const calculateRequestedHours = (start, end, startDayType, endDayType) => {
		const startDate = new Date(start);
		const endDate = new Date(end);
		if (startDate.getTime() === endDate.getTime()) {
			return startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
		}
		let totalHours = 0;
		for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
			const dayOfWeek = date.getDay();
			if (dayOfWeek >= 1 && dayOfWeek <= 4) {
				if (date.toDateString() === startDate.toDateString()) {
					totalHours += startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
				} else if (date.toDateString() === endDate.toDateString()) {
					totalHours += endDayType === 'morning' || endDayType === 'afternoon' ? 5 : 10;
				} else {
					totalHours += 10;
				}
			}
		}
		return totalHours;
	};

	const departmentColors = {
		Production: '#A7F3D0',
		Cleaning: '#D1D5DB',
		Directors: '#81E6D9',
		Engineers: '#FDE68A',
		Floor: '#FB923C',
		G3: '#67E8F9',
		Office: '#F87171',
		Process: '#FBCFE8',
		QC: '#1D4ED8',
		Warehouse: '#C4B5FD',
	};

	const events = holidays.map((holiday) => {
		const hours = calculateRequestedHours(holiday.start_date, holiday.end_date, holiday.start_day_type, holiday.end_day_type)
		const startText = holiday.start_day_type === 'morning' ? 'Morning' : holiday.start_day_type === 'afternoon' ? 'Afternoon' : 'Full Day';
		const endText = holiday.end_day_type === 'morning' ? 'Morning' : holiday.end_day_type === 'afternoon' ? 'Afternoon' : 'Full Day';
		let adjustedEndDate = new Date(holiday.end_date);
		if (holiday.end_day_type === 'full') {
			adjustedEndDate = addDays(adjustedEndDate, 1); // Add one day
			adjustedEndDate = setHours(adjustedEndDate, 0);
			adjustedEndDate = setMinutes(adjustedEndDate, 0);
			adjustedEndDate = setSeconds(adjustedEndDate, 0);
		}
		// Style for half-days to make them visually distinct in the calendar
		const backgroundColor = (departmentColors[holiday.department] || '#D1D5DB');
		return {
			title: `${holiday.firstName} ${holiday.lastName} (${startText} - ${endText}, ${hours}h)`,
			start: holiday.start_date,
			end: adjustedEndDate,
			backgroundColor: backgroundColor,
			borderColor: backgroundColor,
			allDay: true,
			extendedProps: {
				department: holiday.department,
				start_day_type: holiday.start_day_type,
				end_day_type: holiday.end_day_type,
				hours,
			},
		};
	});

	const formatEventDate = (date) => format(new Date(date), 'dd/MM/yyyy', { locale: uk });

	const handleToggleView = () => setShowListView(!showListView);
	useEffect(() => {
		const fetchDataForNewInterval = async () => {
			if (!selectedMonth) return;

			const start = viewMode === 'year'
			? `${new Date(selectedMonth).getFullYear()}-01-01`
			: `${selectedMonth}-01`;

			const end = viewMode === 'year'
			? `${new Date(selectedMonth).getFullYear()}-12-31`
			: new Date(new Date(selectedMonth).getFullYear(), new Date(selectedMonth).getMonth() + 1, 0).toISOString().slice(0, 10);

			await fetchHolidays(start, end, selectedDepartment);
		};

		fetchDataForNewInterval();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMonth, viewMode]);

	useEffect(() => {
		let filteredHolidays = rawHolidays.filter((holiday) => {
			const matchesSearch =
				  holiday.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				  holiday.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				  holiday.department.toLowerCase().includes(searchTerm.toLowerCase());

			const matchesInterval = viewMode === 'year'
			? isWithinInterval(new Date(holiday.start_date), {
				start: new Date(new Date(selectedMonth).getFullYear(), 0, 1),
				end: new Date(new Date(selectedMonth).getFullYear(), 11, 31, 23, 59, 59),
			}) || isWithinInterval(new Date(holiday.end_date), {
				start: new Date(new Date(selectedMonth).getFullYear(), 0, 1),
				end: new Date(new Date(selectedMonth).getFullYear(), 11, 31, 23, 59, 59),
			})
			: isWithinInterval(new Date(holiday.start_date), {
				start: startOfMonth(new Date(selectedMonth || new Date())),
				end: endOfMonth(new Date(selectedMonth || new Date())),
			}) || isWithinInterval(new Date(holiday.end_date), {
				start: startOfMonth(new Date(selectedMonth || new Date())),
				end: endOfMonth(new Date(selectedMonth || new Date())),
			});

			return matchesSearch && matchesInterval;
		});

		filteredHolidays.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
		setSortedHolidays(filteredHolidays);
	}, [rawHolidays, holidays, searchTerm, selectedMonth, viewMode]);
	const handleViewModeChange = (mode) => {
		setViewMode(mode);
		if (mode === 'month') {
			setSelectedMonth(new Date().toISOString().slice(0, 7)); // Mois actuel
		} else {
			setSelectedMonth(`${new Date().getFullYear()}-01`); // Année actuelle
		}
	};

	const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	const handleYearChange = (event) => {
		setSelectedMonth(`${event.target.value}-01`);
	};


	return (
		<div className="container mx-auto p-6">
		<h1 className="text-3xl font-semibold mb-6 text-center">Weekly Holiday Planning</h1>
		<div className="flex justify-between items-center mb-6">
		<button
		onClick={handleToggleView}
className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
>
	{showListView ? 'View Calendar' : 'View List'}
</button>

<div>
	<label className="block text-gray-700 text-sm font-bold mb-2">Filter by Department</label>
<select
value={selectedDepartment}
onChange={(e) => setSelectedDepartment(e.target.value)}
className="border rounded px-4 py-2 w-full"
>
	<option value="">All Departments</option>
{departments.map((dept) => (
	<option key={dept.id} value={dept.name}>{dept.name}</option>
))}
	</select>
</div>

{showListView && (

	<div>
	<div className="flex items-center space-x-4 mb-4">
	<button
	onClick={() => handleViewModeChange('month')}
className={`px-4 py-2 rounded ${viewMode === 'month' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
              >
                View by Month
              </button>
              <button
                onClick={() => handleViewModeChange('year')}
                className={`px-4 py-2 rounded ${viewMode === 'year' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
              >
                View by Year
              </button>
            </div>
            {viewMode === 'month' && (
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Select a Month</label>
                <input
                  type="month"
                  value={selectedMonth || ''}
                  onChange={handleMonthChange}
                  className="border rounded px-4 py-2"
                />
              </div>
            )}

              {viewMode === 'year' && (
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Select a Year</label>
                  <input
                    type="number"
                    value={selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear()}
                    onChange={handleYearChange}
                    className="border rounded px-4 py-2"
                  />
                </div>
              )}
          </div>
        )}
      </div>

      {showListView && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search by name, surname, or department"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
          />
        </div>
      )}

      {!showListView ? (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          views={{
            dayGridYear: {
              type: 'dayGrid',
              duration: { months: 12 },
              buttonText: 'Year',
            },
            dayGridMonth: {
              type: 'dayGrid',
              duration: { months: 1 },
              buttonText: 'Month',
            },
            dayGridWeek: {
              type: 'dayGrid',
              duration: { weeks: 1 },
              buttonText: 'Week',
            },
          }}
          events={events}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridYear,dayGridMonth,dayGridWeek',
          }}
          eventContent={(eventInfo) => (
            <div
              style={{
                backgroundColor: eventInfo.event.backgroundColor,
                padding: '5px',
                borderRadius: '5px',
                margin: '1px',
                marginBottom: '1px',
                color: '#1F2937',
              }}
            >
              <b>{eventInfo.event.title}</b><br />
            </div>
          )}
          nowIndicator={true}
          height="auto"
          datesSet={handleDatesChange}
          displayEventTime={false}
        />
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead className="bg-gold">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">Name</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">Department</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">Start Date</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">End Date</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">Type</th>
				<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase">Hours</th>
              </tr>
            </thead>
            <tbody>
              {sortedHolidays.map((holiday) => (
                <tr
                  key={holiday.id}
                  className="hover:bg-gray-100"
                  style={{ backgroundColor: departmentColors[holiday.department] }}
                >
                  <td className="px-4 py-2 border">{`${holiday.firstName} ${holiday.lastName}`}</td>
                  <td className="px-4 py-2 border">{holiday.department}</td>
                  <td className="px-4 py-2 border">{`${formatEventDate(holiday.start_date)} (${holiday.start_day_type === 'morning' ? 'Morning' : holiday.start_day_type === 'afternoon' ? 'Afternoon' : 'Full Day'})`}</td>
                  <td className="px-4 py-2 border">{`${formatEventDate(holiday.end_date)} (${holiday.end_day_type === 'morning' ? 'Morning' : holiday.end_day_type === 'afternoon' ? 'Afternoon' : 'Full Day'})`}</td>
                  <td className="px-4 py-2 border">{holiday.start_day_type === 'full' && holiday.end_day_type === 'full' ? 'Full Day' : `${holiday.start_day_type || 'Full'} to ${holiday.end_day_type || 'Full'}`}</td>
				  <td className="px-4 py-2 border">{calculateRequestedHours(holiday.start_date, holiday.end_date, holiday.start_day_type, holiday.end_day_type)} hours</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Planning;

import React, { useState, useEffect } from 'react';
import { fetchUserHolidayRequests,cancelHolidayRequest } from './api';
import { toast } from 'react-toastify';

function UserHolidayRequests() {
	const [requests, setRequests] = useState([]);

	useEffect(() => {
		const loadHolidayRequests = async () => {
			try {
				const response = await fetchUserHolidayRequests();
				setRequests(response.data);
			} catch (error) {
				toast.error('Failed to fetch holiday requests');
			}
		};

		loadHolidayRequests();
	}, []);

	const formatDate = (dateString, dayType) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		const formattedDate = `${day}/${month}/${year}`;

		if (dayType === 'morning') {
			return `${formattedDate} (Morning)`;
		} else if (dayType === 'afternoon') {
			return `${formattedDate} (Afternoon)`;
		}
		return formattedDate;
	};
	const handleCancelRequest = async (id) => {
		if (window.confirm('Are you sure you want to cancel this holiday request?')) {
			try {
				await cancelHolidayRequest(id);
				toast.success('Holiday request successfully canceled');
				setRequests((prevRequests) =>
							prevRequests.filter((request) => request.id !== id)
						   );
			} catch (error) {
				toast.error('Failed to cancel holiday request');
			}
		}
	};

	const getStatusText = (approved) => {
		if (approved === "accepted") return 'Approved';
		if (approved === "rejected") return 'Denied';
		if (approved === 'cancelled') return 'Cancelled';
		return 'Pending';
	};

	const calculateRequestedHours = (start, end, startDayType, endDayType) => {
		const startDate = new Date(start);
		const endDate = new Date(end);

		const publicHolidays = [
			"2025-01-01", // New Year's Day
			"2025-04-18", // Good Friday
			"2025-04-21", // Easter Monday
			"2025-05-05", // Early May bank holiday
			"2025-05-26", // Spring bank holiday
			"2025-08-25", // Summer bank holiday
			"2025-12-25", // Christmas Day
			"2025-12-26", // Boxing Day
		];

		const isPublicHoliday = (date) => {
			const formattedDate = date.toISOString().split("T")[0]; // Convertir en "YYYY-MM-DD"
			return publicHolidays.includes(formattedDate);
		};

		if (startDate.getTime() === endDate.getTime()) {
			if (isPublicHoliday(startDate)) {
				return 0; // Pas d'heures pour les jours fériés
			}
			return startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
		}

		let totalHours = 0;

		for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
			const dayOfWeek = date.getDay();
			if (dayOfWeek >= 1 && dayOfWeek <= 4) {
				if (isPublicHoliday(date)) {
					continue; // Ignorer les jours fériés
				}
				if (date.toDateString() === startDate.toDateString()) {
					totalHours += startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
				} else if (date.toDateString() === endDate.toDateString()) {
					totalHours += endDayType === 'morning' || endDayType === 'afternoon' ? 5 : 10;
				} else {
					totalHours += 10;
				}
			}
		}

		return totalHours;
	};

	return (
		<div className="container mx-auto p-6">
		<h1 className="text-3xl font-semibold mb-6 text-center">Your Holiday Requests</h1>
		<div className="hidden md:block overflow-x-auto">
		{/* Table for desktop view */}
<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
	<thead className="bg-gold">
		<tr>
		<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">End Date</th>
<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Requested Vacation Hours</th>
<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason (if denied)</th>
<th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>

</tr>
</thead>
<tbody className="divide-y divide-gray-200">
	{requests.map((request) => (
		<tr key={request.id} className="hover:bg-gray-100">
		<td className="px-6 py-4 whitespace-nowrap">
			<div className="text-sm text-gray-900">{formatDate(request.start_date, request.start_day_type)}</div>
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
													<div className="text-sm text-gray-900">{formatDate(request.end_date, request.end_day_type)}</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
	<div className="text-sm text-gray-900">
		{calculateRequestedHours(request.start_date, request.end_date, request.start_day_type, request.end_day_type)} hours
		 </div>
		 </td>
		 <td className="px-6 py-4 whitespace-nowrap">
		 <div className={`text-sm font-semibold ${request.status === "accepted" ? 'text-green-500' : request.status === "rejected" ? 'text-red-500' : request.status ==='cancelled' ? 'text-blue-500' : 'text-yellow-500'}`}>
                    {getStatusText(request.status)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.deny_reason || 'N/A'}</div>
                </td>
				                <td className="px-6 py-4 whitespace-nowrap">
                  {request.status === "accepted" && (
                    <button
                      onClick={() => handleCancelRequest(request.id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cards for mobile view */}
      <div className="block md:hidden">
        {requests.map((request) => (
          <div key={request.id} className="bg-white border border-gray-300 rounded-lg shadow-md p-4 mb-4">
            <div className="text-sm text-gray-900 font-semibold mb-2">
              Start Date: <span className="font-normal">{formatDate(request.start_date, request.start_day_type)}</span>
            </div>
            <div className="text-sm text-gray-900 font-semibold mb-2">
              End Date: <span className="font-normal">{formatDate(request.end_date, request.end_day_type)}</span>
            </div>
            <div className="text-sm text-gray-900 font-semibold mb-2">
              Requested Hours: <span className="font-normal">{calculateRequestedHours(request.start_date, request.end_date, request.start_day_type, request.end_day_type)} hours</span>
            </div>
            <div className="text-sm font-semibold mb-2">
              Status: <span className={`font-normal ${request.status === "accepted" ? 'text-green-500' : request.status === "rejected" ? 'text-red-500' : 'text-yellow-500'}`}>
                {getStatusText(request.status)}
              </span>
            </div>
            <div className="text-sm text-gray-900 font-semibold">
              Reason: <span className="font-normal">{request.deny_reason || 'N/A'}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserHolidayRequests;

import React, { useState, useEffect } from 'react';
import { fetchVacationDays, submitHolidayRequest } from './api';
import { toast } from 'react-toastify';

function HolidaysRequest() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vacationHours, setVacationHours] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDayType, setStartDayType] = useState('full'); // full, morning, afternoon
  const [endDayType, setEndDayType] = useState('full'); // full, morning, afternoon

  useEffect(() => {
    const getVacationDays = async () => {
      try {
        const response = await fetchVacationDays();
        setVacationHours(response.data.vacation_hours);
      } catch (error) {
        toast.error('Failed to fetch vacation hours');
      }
    };
    getVacationDays();
  }, []);

  function isDateInRestrictedRange(date) {
    const year = date.getFullYear();
    const restrictedStart = new Date(year, 11, 24); // December 24
    const restrictedEnd = new Date(year, 11, 31);   // December 31

    // Normalize time for comparison
    restrictedStart.setHours(0, 0, 0, 0);
    restrictedEnd.setHours(23, 59, 59, 999);
    date.setHours(12, 0, 0, 0); // Ensure date is in the middle of the day

    return date >= restrictedStart && date <= restrictedEnd;
  }

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    const selectedDate = new Date(selectedStartDate);

    if (isDateInRestrictedRange(selectedDate)) {
      toast.error('Start date cannot be between December 24th and 31st inclusive');
      return;
    }

    setStartDate(selectedStartDate);

    // Update the restriction for the end date
    if (new Date(selectedStartDate) > new Date(endDate)) {
      setEndDate(selectedStartDate);
      setMessage(''); // Reset message if dates become valid
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    const selectedDate = new Date(selectedEndDate);

    if (isDateInRestrictedRange(selectedDate)) {
      toast.error('End date cannot be between December 24th and 31st inclusive');
      return;
    }

    if (new Date(selectedEndDate) < new Date(startDate)) {
      setStartDate(selectedEndDate);
      setMessage(''); // Reset message if dates become valid
    }

    setEndDate(selectedEndDate);
  };

  function doesRangeOverlapRestrictedRange(startDate, endDate) {
    const yearStart = startDate.getFullYear();
    const yearEnd = endDate.getFullYear();

    for (let year = yearStart; year <= yearEnd; year++) {
      const restrictedStart = new Date(year, 11, 24);
      const restrictedEnd = new Date(year, 11, 31);

      // Normalize time for comparison
      restrictedStart.setHours(0, 0, 0, 0);
      restrictedEnd.setHours(23, 59, 59, 999);

      if ((startDate <= restrictedEnd && endDate >= restrictedStart)) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (doesRangeOverlapRestrictedRange(start, end)) {
        setMessage('Your selected dates include restricted dates between December 24th and 31st inclusive');
        toast.error('Your selected dates include restricted dates between December 24th and 31st inclusive');
        setLoading(false);
        return;
      }

      await submitHolidayRequest(startDate, endDate, startDayType, endDayType);
      setMessage('Holiday request submitted successfully');
      toast.success('Holiday request submitted successfully');
    } catch (error) {
      setMessage('Failed to submit holiday request');
      toast.error('Failed to submit holiday request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 py-12 px-4">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-semibold mb-6 text-center">Request Holidays</h1>
        <form onSubmit={handleSubmit}>
          <p className="mb-4 p-4 bg-gold text-white text-lg font-bold rounded text-center">
            Please note: The start date is the first day of your holiday, and the end date is included as the last day of your holiday.
          </p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  value="full"
                  checked={startDayType === 'full'}
                  onChange={() => setStartDayType('full')}
                />
                Full Day
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="morning"
                  checked={startDayType === 'morning'}
                  onChange={() => setStartDayType('morning')}
                />
                Morning
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="afternoon"
                  checked={startDayType === 'afternoon'}
                  onChange={() => setStartDayType('afternoon')}
                />
                Afternoon
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">End Date (included):</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate || new Date().toISOString().split('T')[0]} // Block dates before start date
              className="w-full px-3 py-2 border rounded"
              required
              disabled={!startDate}
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  value="full"
                  checked={endDayType === 'full'}
                  onChange={() => setEndDayType('full')}
                  disabled={!endDate}
                />
                Full Day
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="morning"
                  checked={endDayType === 'morning'}
                  onChange={() => setEndDayType('morning')}
                  disabled={!endDate}
                />
                Morning
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="afternoon"
                  checked={endDayType === 'afternoon'}
                  onChange={() => setEndDayType('afternoon')}
                  disabled={!endDate}
                />
                Afternoon
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-gold text-white py-2 px-4 rounded hover:opacity-85"
            disabled={loading || !startDate || !endDate}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit Request'
            )}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        <div className="mt-4 text-center">
          <p className="text-gray-700">You have {vacationHours} vacation hours available.</p>
        </div>
      </div>
    </div>
  );
}

export default HolidaysRequest;
